import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'

import { popupsArr } from './arrs'

export default {
  name: 'step0',
  setup(props, { emit }) {
    const active = ref(true)
    const wrapperMode = ref(false)
    const popups = ref([])

    const init = () => {
      pushPopup(popupsArr, popups.value)
    }
    init()

    const nextStep = () => {
      active.value = false
      wrapperMode.value = true
      setTimeout(() => {
        emit('click', 1)
      }, 500)
    }

    return {
      active,
      popups,
      wrapperMode,
      nextStep,
    }
  },
}
