import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'

import { files, popupsArr, popupsArr2 } from './arrs'
import Emoji from '@/components/@ui/emoji'

export default {
  name: 'step1',
  components: {
    Emoji,
  },
  setup(props, { root }) {
    const active = ref(true)
    const popups = ref([])
    const step = ref(0)
    const wrapperMode = ref(false)
    const filesRow = ref(true)
    const inputNum = ref('_')
    const start = ref(false)

    const lengthDisabledPopup = ref(0)
    const consoleNumItems = ref([])

    const nextStep = async () => {
      step.value += 1
      if (step.value === 1) {
        await pushPopup(popupsArr, popups.value)
        start.value = true
      }
      if (step.value === 2) {
        lengthDisabledPopup.value = 7
        pushPopup(popupsArr2, popups.value)
      }
      if (step.value === 3) {
        root.$router.push('/education1/lesson3')
      }
    }

    const finishCLickFile = () => {
      filesRow.value = false
      setTimeout(() => {
        nextStep()
      }, 500)
    }

    const checkList = () => {
      if (inputNum.value < 5) {
        consoleNumItems.value.push({ num: inputNum.value, step: step.value })
      }
      if (inputNum.value === '1') {
        nextStep()
      }
    }

    return {
      active,
      popups,
      wrapperMode,
      step,
      filesRow,
      inputNum,
      lengthDisabledPopup,
      consoleNumItems,
      start,
      files,
      finishCLickFile,
      nextStep,
      checkList,
    }
  },
}
