export const files = [
  { title: 'Медиа10' },
  { title: 'Драйвер Расширения' },
  { title: 'Язык' },
  { title: 'Веб-Сервер' },
  { title: 'Графический драйвер' },
  { title: 'Виджет' },
]

export const popupsArr = [
  { text: 'У вредоносных программ бывают похожие фрагменты кода, по которым их можно опознать. Для простоты обозначим фрагменты кода в виде эмодзи.' },
  { text: 'Слева представлен код программы, справа – три примера вредоносного кода, которые уже находили ранее в других вирусах. Соотнеси вредоносный код в наших базах с найденным Мидори.' },
  { text: 'Примеры вредоносного кода:' },
  {
    text: `<div class="trojan-title">Троянец</div><img class="trojan-img" src="${require('./img/ex1.png')}" alt="">`,
    black: true,
    emoji: true,
    svg: true,
  },
  {
    text: `<div class="trojan-title">Шифровальщик</div><img class="trojan-img" src="${require('./img/ex2.png')}" alt="">`, black: true, emoji: true, svg: true,
  },
  {
    text: `<div class="trojan-title">Ботнет</div><img class="trojan-img" src="${require('./img/ex3.png')}" alt="">`, black: true, emoji: true, svg: true,
  },
  {
    svg: true,
    text: 'Введи номер строки с вредоносным кодом в консоль и нажми <svg class="svgEnter" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n'
      + '<rect width="24" height="24" fill="url(#pattern0)"/>\n'
      + '<defs>\n'
      + '<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">\n'
      + '<use xlink:href="#image0_854_5462" transform="scale(0.01)"/>\n'
      + '</pattern>\n'
      + '<image id="image0_854_5462" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABmJLR0QA/wD/AP+gvaeTAAADoUlEQVR4nO2csW7TUBSGPwrNQjt2AKUsIKFuCNgqMfAAECooCy/BwsxGC49RVBWQaMtQdlZA6oQqoAgJiQegmQARhhvRxLnBjWrfc2z/n3SHuFHO8f1qX/v6XIMQQgjRLFrAMrAO7AFdoOesdfu5rfdzbZXSEw5YAvax7/BJ22fgVgn9YcYU8Bj7jj1uW+3vS+V5gn1nFtVWCu6b5Cxh34lFt06hPZSQFuH8a92BRbd9KjrQL2PfeWW129mdrcLgUqsrkwwjp60qCLlinUCJXM1uOGGRxYQcADPWSZREF5gd3FAFIT3rBEpmyEEVTlmNQkKcISHOkBBnSIgzJMQZEuIMCXGGhDhDQpwhIc6QEGdIiDMkxBkS4gwJOWQHaAPzwGvjXFyTquCgPRBzPmHcIfTE8JBsX5jE1SnLGRLiDAlxhoQ4Q0KcISHOkBBnnDri91qEwuAOcJlwE3W6rKTE/6nqmr5j3TEbxh1LXdb01UZIndb0VV5IHdf0VUJIbHKxBXwAzkf+VmfcTi52aJ4MN8SE1HlNn3tiQuq8ps89MSFnkmch/hEb1FMNZt5wO6gLQyTEGRLiDA9CtghlN6qHGkPK6YoNhh8BtBPHdzd1YnmEPAPuAb8HtlnViR1kPs9Gv2WExZFB//NmovjZtpfJ5WLC2LlYydhI2AnZtpbJ53rC2Lk0TUaP0ReJPUwYO5eyAj9nVMZJ4GnCnY+12Kv2dhPGz6WMoF6PjD/AjUxeC4lzyKXogF6PjB7wKLL/qS8scikymOcjY4XRy/5Fg1xyqbuMj8DNyH7PAV8N8smlbjIOCDUCa4SrqenIPreANwa5JRGyydErIj0wh52MJELmJ+4SOxaxOU0lFXJu0l4xYAF4ia2IqJAyHuFuAXcYnjS0ZIYwi3wWuEYY0C+ZZjRM7oRqEdZfEL/3WCvo9+vUcikqkKQ4EyIpDoVIikMhkuJQiKQ4FCIpDoVIikMhPfwVOTReyDgplnVZLoRY1mXdJTw1HJRShfd3JSf1f8gmh6/W2zGIb92G0PoQe7Q+xDMS4gwJcUZMSDd5Fs3lR3ZDTMj3BImIwEhfx4S8S5CICLzNbogJ2U6QiAi8ym6I3YdMEwrLLpSeTrP5Qqh++Tm4MXaE/AIepMiowfSA+2Rk5NGkt8mlbrGq+1ymgFUHydepjau6n4gO8MnBzlS9jau6H+Ko093T/R/rEF7f1CZUBIrxdIFvwHvCles2YXwWQgghSuEvqsaA2T/sSpIAAAAASUVORK5CYII="/>\n'
      + '</defs>\n'
      + '</svg> Enter',
  },
]

export const popupsArr2 = [
  { text: 'Улику нашли! Отправлю ее Мидори Кума для дальнейшего исследования.', buttonTitle: 'ПРОДОЛЖИТЬ' },
]
